<!-- 题目 -->
<template>
  <div class="answerPage">
    <screen></screen>
    <!-- 活动指导 -->
    <span class="activity-guidance" @click="dialogVisible = true">
      <i class="el-icon-warning"></i>
      <span>活动指导</span>
    </span>
    <!-- 计时器 -->
    <span class="timer-text">
      学习该教学活动时长：
      <timer :totalTime="totalTime"></timer>
    </span>
    <!-- 题 -->
    <el-card class="active" ref="barparent">
      <el-card class="answer">
        <div>
          <span>
            问题集:&nbsp;{{questions.title}}
            <i v-if="false">[ {{questionsType[questions.type]}} ]</i>
          </span>
          <p>{{questions.des}}</p>
        </div>
      </el-card>
      <el-card class="box-card-left">
        <el-scrollbar style="height:90%">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            @node-click="handleNodeClick"
            :highlight-current="true"
            :default-expanded-keys="treeArr"
            node-key="nodeid"
            class="tree"
            ref="tree"
          ></el-tree>
        </el-scrollbar>
        <!-- 我是内容 -->
      </el-card>
      <div class="resize"></div>
      <el-card class="box-card-right">
        <el-scrollbar style="height:100%">
          <div v-if="answers.length>0">
            <div
              v-for="(item,i) in answers"
              :key="i"
              class="text-option"
              @click="choose(item)"
              :class="status == 0&&item.isCorrect == 1 ? 'text-option green':isComplete == 1? item.userChoce==1 ? 'text-option class3':'text-option':chooseIdObj[item.id]==1 ? 'text-option class3':'text-option'"
              v-html="item.solution"
            ></div>
          </div>
          <div v-html="detailContent" v-else></div>
        </el-scrollbar>
      </el-card>
    </el-card>
    <div :class="this.$store.state.toggle_collapsed?'activity-button wth':'activity-button w100'">
      <el-button
        :disabled="showButton"
        @click="submit"
        type="primary"
        v-fence="{data: '', functions:['创建IMX活动']}"
        style="padding-right: 14px;"
      >提交</el-button>
      <el-button
        :disabled="showButton"
        @click="submit"
        type="primary"
        v-fence="{data: '', functions:['IMX做题']}"
        style="padding-right: 14px;"
      >提交 ({{time}})</el-button>

      <el-button @click="signOut">退出</el-button>
    </div>
    <!-- 活动指导提示框 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span style="white-space: pre-wrap;">{{knowledge.teachingMessage}}</span>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import { drag } from '../../util/drag.js'
import screen from '@/components/screen'
import timer from '@/components/timer_s'
import { activeDetails } from '../../api/api'
export default {
  data () {
    return {
      count: 0,//用时时长
      time: '00:00',//提交按钮的时间
      questions: {//问题集合
        title: '',
        type: 1,
        des: ''
      },
      questionsType: {//题目类型
        1: '单选题',
        2: '多选题'
      },
      treeData: [],//左边的题目
      treeArr: [],//选中的数组(大概)
      treeLastArr: [],
      defaultProps: {
        children: 'children',
        label: 'nodeName'
      },
      knowledge: {},//活动详情的数据
      dialogVisible: false,//活动指导提示框
      totalTime: 0,//学习该教学活动时长
      showButton: true,//是否禁用提交按钮
      expandInterval: null,//定时器id
      currentId: null,//选中节点的id(大概)
      expandTimeArr: [],
      current: null,
      nodeid: '',//当前节点的id(大概)
      optionSN: null,
      detailContent: '',//右侧的题目详情
      answers: [],//右侧的答案选项
      chooseIdObj: {},
      isComplete: 1,//是否做完该题目
      paramsObj: {},//该活动的变式
      startTime: '',//开始做题时间
      completionStatus: false,//(该字段没用到)
      status: 0,//0  老师  1  学生
      appIdUser: null,//当前登录的用户id
    }
  },

  components: {
    screen,
    timer
  },
  created () {
  },
  mounted () {
    this.startTime = new Date().getTime();
    this.startHandler();
    this.load();
    this.getUserSpendTime();
    drag.dragControllerDiv('resize', 'box-card-left', 'box-card-right', 'active')
    this.analysis()
    // 3秒保存时间
    let timeId = setInterval(() => {
      this.answerTime()
      // 保存定时器id
      this.$store.commit('timeRefresh', timeId)
    }, 3000);
  },
  watch: {
  },
  methods: {
    // 解析appIds
    analysis () {
      if (this.utils.identity('K12做题') || this.utils.identity('IMX做题')) {
        this.status = 1
      }
      var urlAppId = window.location.href.split("=")[2].split("#")[0];
      let appIds = JSON.parse(localStorage.getItem("appIds"));
      if (appIds) {
        appIds.forEach((item) => {
          if (item.appId === urlAppId) {
            this.appIdUser = item.userId;
          }
        });
      }
    },
    handleNodeClick (e) {
      if (e.optionSN > 0) {  //排除根节点
        let param = {
          nodeId: e.nodeid,
          activeId: this.paramsObj.activeId,
          caseId: this.paramsObj.caseId, //变式id
          isComplete: this.paramsObj.isComplete, // 1答案，0做题
          taskId: this.paramsObj.id,
        }

        //请求右侧----------
        this.$api.page.getIMMXDetail(param).then((res) => {
          this.answers = [];
          this.detailContent = '';
          if (res.data.data) {
            if (res.data.data.detail) {   //右侧描述
              this.detailContent = res.data.data.detail.detail;
            } else if (res.data.data.answers) {  //右侧选项
              this.answers = res.data.data.answers
            }
          }
        })
        if (this.current) {  //排除第一个点击节点
          this.expandTimeArr.push({
            nodeId: this.nodeid,
            spendTime: this.current,
            optionSN: this.optionSN,
            recordTime: new Date().getTime()
          })
        }
        this.nodeid = e.nodeid
        this.optionSN = e.optionSN
        this.current = null
        clearInterval(this.expandInterval)
        this.expandInterval = setInterval(() => {
          this.currentId = e.nodeid;
          this.current++;
        }, 1000)
      }
    },
    getImmxCase (paramsObj) { //get数据
      let params = {
        "activeId": paramsObj.activeId,
        "caseId": paramsObj.caseId,
        "caseType": 1,
        "isComplete": paramsObj.isComplete,
        "taskId": paramsObj.id,
      }
      this.$api.page.getImmxCase(params).then((res) => {
        if (res.data.data) {
          let arr = res.data.data;
          arr.nodes.forEach((item, index) => {
            if (index == arr.nodes.length - 1) {
              this.treeArr.push(item.nodeid)
              this.treeLastArr.push(item.children[0].nodeid)
            }
          })
          setTimeout(() => {
            this.$refs.tree.setCurrentKey(this.treeLastArr[0])
            let obj = {
              'nodeid': this.treeLastArr[0],
              'optionSN': 1
            }
            this.handleNodeClick(obj)
          }, 100);
          this.treeData = arr.nodes;
          this.questions = {
            title: arr.psname,
            des: '',
            type: 0
          }
        }
      })
    },
    choose (item) {
      if (this.status == 0) {
        return;
      }
      if (this.chooseIdObj[item.id] == null) {
        this.chooseIdObj = {}
        this.chooseIdObj[item.id] = 1
        this.chooseIdObj.isCorrect = item.isCorrect;
        this.chooseIdObj.optionSN = item.optionSN;
      } else {
        this.chooseIdObj = {}
      }
      if (Object.keys(this.chooseIdObj).length > 0) {
        this.showButton = false
      } else {
        this.showButton = true
      }
    },
    submit () { //提交
      this.showButton = true
      clearInterval(this.expandInterval) //清除定时器
      this.expandTimeArr.push({
        nodeId: this.currentId,
        spendTime: this.current,
        optionSN: this.optionSN,
        recordTime: new Date().getTime()
      })

      let params = {
        "activeId": this.paramsObj.activeId,
        "caseId": this.paramsObj.caseId,
        "answerBean": {
          "answerTime": new Date().getTime(),
          "isCorrect": this.chooseIdObj.isCorrect,
          "optionSN": this.chooseIdObj.optionSN,
          "psAnswerId": Object.keys(this.chooseIdObj)[0],
          "taskId": this.paramsObj.id
        },
        "nodeBean": this.expandTimeArr,
        "taskId": this.paramsObj.id,
        'startTime': this.startTime, //开始做题时间
        'finishTime': new Date().getTime(),//提交时间
        'totalSeconds': this.count  //用时长
      }

      this.$api.page.saveUserIMMXAnswer(params).then((res) => {

        if (res.data.code == 0) {
          //提交成功
          setTimeout(() => {
            this.signOut()
          }, 2000)
        }

      })
    },
    // 退出
    signOut () {
      if (this.utils.identity('IMX做题')) {
        this.answerTime()
      }
      this.$router.go(-1)
    },
    //计时
    startHandler () {
      let set = setInterval(() => {
        this.count++;
        this.time = (Math.floor(this.count / 60) > 9 ? Math.floor(this.count / 60) : '0' + Math.floor(this.count / 60)) + ':' + (this.count % 60 > 9 ? this.count % 60 : '0' + this.count % 60)
      }, 1000);
    },
    load () {
      let id = this.$route.query.id || 4
      activeDetails({ 'id': id }).then(res => {
        if (res.data) {
          this.paramsObj = res.data.stems[0];
          if (this.paramsObj) {
            this.isComplete = this.paramsObj.isComplete;
            this.getImmxCase(this.paramsObj);
          }
          this.knowledge = res.data
          if (localStorage.answer_time) {
            let arr = []
            let key = this.$route.query.id + '~' + this.paramsObj.caseId + '~' + this.appIdUser
            // 如果本地有
            arr = JSON.parse(localStorage.getItem('answer_time'))
            let isKey = null
            let isValue = null
            arr.forEach((item, index) => {
              Object.keys(item).forEach(item => {
                if (item == key) {
                  isKey = arr[index]
                  isValue = arr[index][item]
                }
              })
            })
            // 是当前的题目
            if (isKey) {
              this.count = isKey[key]
            }
          }
        }
        if (res.data.stems[0].isComplete == 1) {
          this.completionStatus = true
        }
      })
    },
    getUserSpendTime () {
      let params = {
        "activeId": Number(this.$route.query.id),
        "activeType": 1
      }
      this.$api.page.getUserSpendTime(params).then((res) => {
        if (res.data) {
          this.totalTime = res.data.data
        }
      })
    },
    // 本地保存时间
    answerTime (val) {
      let arr = []
      let key = this.$route.query.id + '~' + this.paramsObj.caseId + '~' + this.appIdUser
      // 如果本地没有
      if (!localStorage.answer_time) {
        let obj = {}
        obj[key] = this.count
        arr.push(obj)
      } else {
        // 如果本地有
        arr = JSON.parse(localStorage.getItem('answer_time'))
        let isKey = null
        let isValue = null
        arr.forEach((item, index) => {
          Object.keys(item).forEach(item => {
            if (item == key) {
              isKey = arr[index]
              isValue = arr[index][item]
            }
          })
        })
        // 是当前的题目
        if (isKey) {
          isKey[key] = this.count
        } else {
          let obj = {}
          obj[key] = this.count
          arr.push(obj)
        }
      }
      localStorage.setItem('answer_time', JSON.stringify(arr))
    },
  }
}

</script>
<style lang='less' scoped>
@import '../../style/answerPage/answerPage.less';
@import '../../style/mouse.less';
.class3 {
  background: #d8e8ff;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.des /deep/ p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
}
.des/deep/img {
  vertical-align: text-top;
}
.stemContent/deep/img {
  vertical-align: middle;
}
.choices/deep/p {
  display: inline-block;
  & img {
    height: 100%;
    vertical-align: middle;
  }
}
.tree/deep/.el-tree-node__expand-icon.is-leaf {
  width: 12px;
  height: 12px;
  font-size: 16px;
  background-size: 16px;
  margin-right: 5px;
  background: #615a5a;
  border-radius: 50%;
}
.tree/deep/.el-tree-node.is-current > .el-tree-node__content {
  background: #d8e8ff;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.el-scrollbar/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
.answerPage/deep/table {
  margin-top: 10px;
}
.answerPage/deep/table td {
  border: 1px solid #615a5a;
  vertical-align: middle;
  text-align: center;
  height: 70px;
  padding: 12px 0;
}
.answerPage/deep/table tr:first-child {
  font-weight: 600;
}
.resize {
  display: inline-block;
  width: 8px;
  background-color: #f6f6f6;
  height: 670px;
  cursor: pointer;
}
</style>
